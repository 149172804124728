var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['form-group', _vm.classFormGroup]},[(_vm.textFloat)?_c('label',{attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.textFloat)+" "),(_vm.required)?_c('font',{attrs:{"color":"red"}},[_vm._v("*")]):_vm._e()],1):_vm._e(),_c('b-input-group',{class:[
      'input-group-prepend-custom',
      { display: _vm.prefix },
      { 'error-input': _vm.isError }
    ],attrs:{"prepend":_vm.prefix + ' - '}},[(_vm.renderComponent)?_c('input',{ref:"formInput",class:[
        'form-control',
        'input-text',
        { 'error-input': _vm.isError },
        { 'input-has-prefix': _vm.prefix },
        { 'color-placeholder': _vm.changeColorPlaceholder }
      ],attrs:{"id":_vm.id,"type":_vm.type,"placeholder":_vm.placeholder,"pattern":_vm.pattern,"maxlength":_vm.maxlength,"disabled":_vm.isDisabled,"inputmode":_vm.inputmode,"formatter":_vm.maxlengthHandler},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.inputChange($event)},"keypress":_vm.onlyNumber}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }